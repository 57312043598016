import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../shared/Layout"
import { GradientFooterBg } from "../shared/Footer"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import JobApplicationForm from "../careers/JobApplicationForm"
import CircleBg from "../../../static/svg/Large-Circle.svg"
import { Heading20, Heading48, Paragraph16 } from "../../styles/PageStyles"

const CareersTemplate = ({ data }) => {
  const [showModal, setShowModal] = useState(false)

  const handleModal = () => {
    setShowModal((prev) => !prev)
  }

  const { html } = data?.markdownRemark
  const {
    title,
    date,
    careerLevel,
    qualifications,
    yearsOfExperience,
    jobType,
    jobSpecialization,
  } = data?.markdownRemark?.frontmatter

  return (
    <Layout modal={showModal}>
      <StyledContainer fluid>
        <JobPosition className="job-position">
          <div className="heading">
            <Heading48>{title}</Heading48>
          </div>
          <div className="subheading">
            <Paragraph16 className="date">Posted: {date}</Paragraph16>
            <button className="apply-now" onClick={handleModal}>
              Apply Now
            </button>

            <JobApplicationForm
              modal
              show={showModal}
              setShowModal={setShowModal}
            />
          </div>
          <div className="body" dangerouslySetInnerHTML={{ __html: html }} />
          <div className="additional-info">
            <Heading20>Additional Information</Heading20>
          </div>
          <div className="additional-info">
            <Paragraph16>Career Level</Paragraph16>
            <Paragraph16>{careerLevel}</Paragraph16>
          </div>
          <div className="additional-info">
            <Paragraph16>Qualification</Paragraph16>
            <Paragraph16>
              {qualifications?.map((qualification, index) => (
                <span key={index}>{qualification}</span>
              ))}
            </Paragraph16>
          </div>
          <div className="additional-info">
            <Paragraph16>Years of Experience</Paragraph16>
            <Paragraph16>{yearsOfExperience}</Paragraph16>
          </div>
          <div className="additional-info">
            <Paragraph16>Job Type</Paragraph16>
            <Paragraph16>{jobType}</Paragraph16>
          </div>
          <div className="additional-info">
            <Paragraph16>Job Specialization</Paragraph16>
            <Paragraph16>
              {jobSpecialization?.map((specialization, index) => (
                <span key={index}>{specialization}</span>
              ))}
            </Paragraph16>
          </div>
        </JobPosition>
        <div className="horizontal-line"></div>
        {/* <Testimonial /> */}
        <CircleBg className="circle-bg" />
        <GradientFooterBg marginLeft zeroIndex />
      </StyledContainer>
    </Layout>
  )
}

export default CareersTemplate

const StyledContainer = styled(Container)`
  position: relative;
  padding: 150px 10% 100px 10%;
  overflow: hidden;
  min-height: 80vh;

  .horizontal-line {
    height: 4px;
    background: #e9eff1;
  }

  .circle-bg {
    position: absolute;
    bottom: -445px;
    left: -696px;
    z-index: 0;
  }
`

const JobPosition = styled.div`
  position: relative;
  margin-bottom: 50px;

  p,
  span,
  li {
    color: #5b5d5e;
    letter-spacing: 0.06em;
  }

  .heading {
    h1 {
      font-weight: bold;
      margin-bottom: 50px;
    }
  }

  .subheading {
    display: flex;
    justify-content: space-between;
  }

  .apply-now {
    height: fit-content;
    white-space: nowrap;
    border: none;
    background: #0073af;
    border-radius: 20px;
    padding: 10px 20px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.06em;
    color: #ffffff;
  }

  .date {
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #0073af;
    font-weight: bold;
  }

  .additional-info {
    margin: 25px 0;

    span {
      &:after {
        content: ", ";
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .body {
      p,
      li {
        font-size: 14px;
      }
    }
  }
`

export const query = graphql`
  query CareersTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        careerLevel
        qualifications
        yearsOfExperience
        jobType
        jobSpecialization
        date(formatString: "DD MMMM YYYY")
      }
    }
  }
`
